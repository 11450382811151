(() => {
    "use strict"
    
    const toggles = document.querySelectorAll('[data-toggle]')
    const tableToggles = document.querySelectorAll('[data-table-toggle]')

    toggles.forEach(toggle => {
        toggle.addEventListener('click', evt => {
            toggles.forEach(tgl => {
                tgl.classList.remove('is-active')
                document.querySelector(`[data-element="${tgl.dataset.toggle}"]`).classList.remove('is-active')
            })
            evt.target.classList.add('is-active')
            document.querySelector(`[data-element="${evt.target.dataset.toggle}"`).classList.add('is-active')
        })
    })

    tableToggles.forEach(toggle => {
        toggle.addEventListener('click', evt => {
            tableToggles.forEach(tgl => {
                tgl.classList.remove('is-active')
                document.querySelector(`[data-element="${tgl.dataset.tableToggle}"]`).classList.remove('is-active')
            })
            evt.target.classList.add('is-active')
            document.querySelector(`[data-element="${evt.target.dataset.tableToggle}"]`).classList.add('is-active')
        })
    })

    // Alerts carousel at the top of the page
    if (document.getElementById('alerts')) {
        new Splide('#alerts', {
            type: 'loop',
            arrows: false,
            pagination: false,
            perPage: 1,
            perMove: 1,
            gap: 0,
            autoplay: true,
            autoplaySpeed: 3000
        })
        .mount()
    }

    // Nav toggle
    if (document.querySelector("#nav-menu-toggle")) {
        document.getElementById('nav-menu-toggle').addEventListener('click', function () {
            this.classList.toggle('open')
            document.getElementById('nav').classList.toggle('active')
        })
    }

    // Tools across the website
    if (document.querySelector('#tools')) {
        new Splide('#tools', {
            type: 'loop',
            arrows: true,
            pagination: false,
            perPage: 3,
            perMove: 1,
            gap: 'clamp(1.5rem, 3vw, 3rem)',
            autoplay: true,
            autoplaySpeed: 3000,
            breakpoints: {
                750: {
                    perPage: 2,
                },
                525: {
                    perPage: 1,
                }
            }
        }).mount()
    }

    // FAQs across the website
    if (document.querySelector('.faq')) {
        const faqs = document.querySelectorAll('.faq')
        for (let i = 0; i < faqs.length; i++) {
            faqs[i].addEventListener('click', function (e) {
                this.classList.toggle('faq--active')
            })
        }
    }

    // Related above the page footer
    if (document.querySelector('#related_news')) {
        new Splide('#related_news', {
            type: 'loop',
            arrows: false,
            pagination: false,
            perPage: 2,
            perMove: 1,
            gap: 'clamp(1.5rem, 3vw, 3rem)',
            autoplay: true,
            autoplaySpeed: 3000,
            breakpoints: {
                1080: {
                    perPage: 1
                },
                768: {
                    perPage: 2
                },
                550: {
                    perPage: 1
                }
            }
        }).mount()
    }

    // Reasons slider above the page footer
    if (document.querySelector('#reasons')) {
        new Splide('#reasons', {
            type: 'loop',
            arrows: false,
            pagination: false,
            perPage: 3,
            perMove: 1,
            breakpoints: {
                1040: {
                    perPage: 2
                },
                640: {
                    perPage: 1
                }
            }
        }).mount();
    }

    // Animate on scroll across the site
    if (document.querySelector('[data-aos]')) {
        AOS.init({
            offset: 0,
            duration: 1200,
            disable: 'mobile',
            once: true
        });

        document.addEventListener('aos:in', ({ detail }) => {
            if (detail.dataset.animate) {
                animateNumber(detail);
            }
        });
    }

    // Cookies
    var cookieButtons = document.querySelectorAll('.cookie-acceptance__button')
    if (cookieButtons != null) {
        for (var cookieButton of cookieButtons) {
            cookieButton.addEventListener('click', function () {
                document.getElementById('cookie-notice').classList.add('hidden')
                let formData = new FormData()
                formData.append('cookie', this.id)

                fetch('/scripts/cookie.php', {
                    method: 'POST',
                    body: formData
                })
            })
        }
    }

    // Web forms
    if (document.querySelector('[name="jsToken"]') && document.querySelector('[name="formTime"]')) {
        document.querySelector('[name="jsToken"').value = Math.random().toString(36).substring(2, 10)
        document.querySelector('[name="formTime"]').value = Date.now()
    }

    /**
     * Animate the increment of numbers from 0 to max value
     * @param {Object} element 
     */
    function animateNumber(element) {
        let currentNumber = 0;
        const maxNumber = parseFloat(element.textContent)
        const step = Number(element.dataset.step) || 10
        const decimals = Number(element.dataset.decimals) || 0

        // Interval function to update the number every 100 milliseconds
        const interval = setInterval(function() {
            // Calculate the increment based on the current value
            let increment = currentNumber < 10 ? step : 10

            // Update the current number with the calculated increment
            currentNumber += increment

            // Set the final value to 100 if it exceeds
            if (currentNumber >= maxNumber) {
                clearInterval(interval)
                currentNumber = maxNumber
            }

            // Update the display
            if (maxNumber > 1) {
                element.textContent = round(currentNumber, 2).toFixed(decimals)
            }
            else {
                element.textContent = round(currentNumber * 100)
            }
        }, 100)
    }

    /**
     * Round the number to the desired precision
     * @param {*} value 
     * @param {*} precision 
     * @returns 
     */
    function round(value, precision) {
        var multiplier = Math.pow(10, precision || 0)
        return Math.round(value * multiplier) / multiplier
    }

    // Automatically Update the Live metal prices every minute
    window.setInterval(() => {
        fetch("/scripts/live-pricing-update.php")
            .then(res => res.json())
            .then(data => {
                if (! document.querySelector(".header__metal-prices")) { 
                    return false;
                }
                document.querySelector(".header__metal-prices").innerHTML = data; // Removes the Event listener
                // Reapply Event Listener
                applyToggleMetalTable();

                document.querySelector(".header__metal-prices").classList.add("highlight");
                setTimeout(() => {
                    document.querySelector(".header__metal-prices").classList.remove("highlight");
                }, 2000);
            });
    }, 60000); // Every minute 
    
    
    function applyToggleMetalTable() {
        document.querySelectorAll('[data-table-toggle]').forEach(toggle => {
            toggle.addEventListener('click', evt => {
                document.querySelectorAll('[data-table-toggle]').forEach(tgl => {
                    tgl.classList.remove('is-active')
                    document.querySelector(`[data-element="${tgl.dataset.tableToggle}"]`).classList.remove('is-active')
                })
                evt.target.classList.add('is-active')
                document.querySelector(`[data-element="${evt.target.dataset.tableToggle}"]`).classList.add('is-active')
            })
        });
    }

    if (document.querySelector('[data-play]')) {
        const playButtons = document.querySelectorAll('[data-play]')

        playButtons.forEach(button => button.addEventListener('click', evt => {
            const video = document.querySelector(`[data-video="${evt.target.dataset.play}"]`)
            const overlay = document.querySelector(`[data-overlay="${evt.target.dataset.play}"]`)

            if (video && overlay) {
                video.src = video.dataset.src
                video.click()

                setTimeout(() => {
                    overlay.style.display = 'none'
                }, 300)
            }
        }))
    }

    /**
     * Observe images and dynamically update the width and height attributes
     */
    function observeImages() {
        const images = document.querySelectorAll('img')

        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                const { target } = entry
                const { height, width } = entry.contentRect
                
                target.height = round(height, 0)
                target.width = round(width, 0)
            })
        })

        images.forEach(image => {
            resizeObserver.observe(image)
        })
    }

    if (typeof ResizeObserver !== 'undefined') {
        observeImages()
    }

})()